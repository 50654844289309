<template>
      
  <div>
    <div class="banner">
          <img :src="banner" alt="">
      </div>
   <div class="back">

    <div class="banner_kaifang">
      
      <div class="titles">
        <img src="../assets/anzuo.png" alt="" />
        开放公告
        <img src="../assets/anyou.png" alt="" />
      </div>
      <div class="doalan_top">
        <div class="doalan_lei">
          <div class="left">
            <span class="doalan_kai"> 免费开放 </span>
            <img src="../assets/tourService/fan.png" class="shanz" alt="" />
            <div class="doalan_kais"> 
            
            <img src="../assets/tourService/Time.png">
            </div><br />
            <div class="colorEs"> 除法定节假日外每周一闭馆 </div>
            <div class="timeList">
              <div>09:00 — 17:30</div>
              <br />
              <span> 周二至周五
                
                （17:00）停止入馆
 </span>
              <div>09:00 — 20:00</div>
              <br />
              <span> 周六至周日（19:30）停止入馆 </span>
            </div>
          </div>
          <img src="../assets/tourService/middleTu.png" id="wui" alt="" />
          <div class="doalan_righttwo">
            <div class="doalan_kais"> <img src="../assets/tourService/recommend.png"> </div>
            <img src="../assets/tourService/fan.png" class="shanz" alt="" />
            <span class="doalan_kaies"> 参观路线 </span>
            <div class="doalan_Es"> 入馆参观需验证健康码,测体温，刷身份证 </div>
            <div style="textalign: left">
              <span class="doalan_lie">
                
             全馆参观大约需要1-2小时。博物馆西门进入—地下沙盘-临展厅-电梯上楼-顺义历史文化<br />
              </span>
            </div>
          </div>
        </div>
      </div>
      <img
        class="borderImg"
        src="../assets/tourService/vectors.png"
        alt=""
      />
      <div class="xuzhi" ref="notice">
        <img src="../assets/tourService/curve2.png" id="su" alt="" />
        <div id="suright"></div>
        <div id="suDetails">
          <div class="cp">（1） 顺义区博物馆所有展览免费向公众提供</div>
          <div class="cp">（2） 参观需提前预约，凭有效预约短信及有效身份证件进馆。</div>
          <div class="cp">（3） 酗酒者、衣冠不整者以及无行为能力或限制行为能力者无监护人陪伴的，谢绝入馆。</div>
          <div class="cp">（4） 自觉接受安检，严禁将易燃易爆、管制械具等危险品带入馆内。</div>
          <div class="cp">（5） 参观前请将随身包裹寄存，贵重物品自行保管。</div>
          <div class="cp">（6） 展厅内请勿摄像、拍照时请勿使用闪光灯及三脚架。</div>
          <div class="cp">（7） 请爱护并正确使用公共设施、请勿触摸文物及展品，损坏需按规定赔偿。</div>
          <div class="cp">（8） 请自觉维护环境卫生，请勿丢弃杂物、随地吐痰、乱贴乱画、请勿在馆内吸烟。</div>
          <div class="cp">（9） 请勿鞋带宠物入馆。</div>
          <div class="cp">（10） 自觉遵守参观秩序、请勿大声喧哗、追跑打闹。</div>
          <div class="cp">（11） 如遇到各类突发事件，请服从工作人员指挥。</div>     
        </div>
      </div>
      <div class="yuyue">
        <img src="../assets/tourService/object.png" alt="" />
        <div class="left yuyueleft">
          <div class="pinTitle">预约讲解服务</div>
          <div class="p">
          预约电话（8147 5286 转1）<br />
          请提供以下预约内容：<br />
          具体时间、单位名称、参观人数、联系电话、联系人<br />
          周二至周五9:00—11:00 13:30—17:00 <br>
          周六、日9:00—11:00 13:30—19:30<br>
          </div>
          
        </div>
        <div class="doalan_right yuyueright">
          <div class="yue">
            <div class="pinTitle zongColor">非预约讲解服务</div>
            <div class="timesList">
                <span>讲解时间</span> <br />
                <span>上午：09:30 - 10:30</span><br />
                <span>下午：15:00 - 16:00</span>
                
            </div>
            
             
            
          </div>
        </div>
      </div>
      <div class="titl" style="marginTop:30px">
        <img src="../assets/liangzuo.png" alt="" />
        便民服务

        <img src="../assets/liangyou.png" alt="" />
        <br />
      </div>
      <div class="danlan_bianmin">
          <div class="daolan_out daolan_marBottomOne">
            <div class="daolan_titleImg">
                <img src="../assets/tourService/icon.png" alt="">
            </div>
            <div class="daolan_text">
                <span class="daolan_title" >咨询服务</span>
                <span class="daolan_details" >顺义博物馆服务台（南门入口处）</span>
            </div>
          </div>
          <div class="daolan_out daolan_marBottomTwo">
            <div class="daolan_titleImg">
                <img src="../assets/tourService/icon(1).png" alt="">
            </div>
            <div class="daolan_text">
                <span class="daolan_title" >电子自助寄存柜</span>
                <span class="daolan_details" >凭有效证件当日可免费寄存，无时长限制</span>
            </div>
          </div>
          <div class="daolan_out">
            <div class="daolan_titleImg">
                <img src="../assets/tourService/icon(2).png" alt="">
            </div>
            <div class="daolan_text">
                <span class="daolan_title" >无障碍服务</span>
                <span class="daolan_details" >我馆为特殊群体可以感受到社会的关爱，共享文明社会建设成果，特设无障碍坡道、通道、电梯、卫生间等无障碍设施。</span>
            </div>
          </div>
          <div class="daolan_out">
            <div class="daolan_titleImg">
                <img src="../assets/tourService/icon(5).png" alt="">
            </div>
            <div class="daolan_text">
                <span class="daolan_title" >医疗急救服务</span>
                <span class="daolan_details" >如果您在参观过程中突感身体不适或意外受伤，请及时与工作人员联系，若情况紧急，工作人员将尽
                  快联系急救，送您到距离最近的正规医院进行处理。</span>
            </div>
          </div>
      </div>
      <div class="titl" ref="trafficRoutes">
        <img src="../assets/liangzuo.png" alt="" />
        交通路线

        <img src="../assets/liangyou.png" alt="" />
        <br />
      </div>
      <div class="luxian">
          <!-- 地图功能 -->
          <div id="allmap" ></div>
          
          <div class="luxianCont">
            <div class="mapsRight">
            <div class="heads">
                <div class="left banLeft">
                    <img src="../assets/tourService/logoCopy.png" alt="">
                    顺义数字博物馆
                </div>
                <div class="left banRight" style="lineHeihgt:70px;fontSize:16px" >
                    <img src="../assets/tourService/address.png" style="width:20px;height:22px;" alt="">
                    北京市顺义区石园街道石园南大街顺义文化中心内
                </div>
            </div>
          </div>
          <div class="bor">
              <div></div>
          </div>
          <div class="mapsRight">
            <div class="head">
              <div class="biankuang">

              </div>
                <div class="lefts">
                (1) 乘顺2路到老干部局站下车，向西步行至红绿灯路口再向南步行200米左右到达顺义文化中心西门。<br>
                (2) 乘顺13路到电信大楼站下车，向北步行200米左右到达顺义文化中心西门。<br>
(3)  乘顺5路到电信大楼站下车，向东步行至红绿灯路口过马路后再向北步500米左右到达顺义文化中心西门。<br>
(4) 乘顺1、顺3、顺21、顺42路到石园东区站下车，向东步行至红绿灯路口过马路后再向北走500米到达顺义文化中心西门。<br>
(5) 乘850、850快车到石园东区北门站下车，向东步行至红绿灯路口过马路后再向南步行200米左右到达顺义文化中心西门。
                </div> 
                <img src="../assets/tourService/bus.png" alt="">
            </div>
          </div>
          <div class="mapsRights">
              <div class="headthree">
                <div class="leftes">
                   请注意： <br>
                   顺13和顺5路虽然到达的站名字一样 ，但是站的位置不一样。 <br>
                   文化中心院内暂不对外提供车位，建议广大游客绿色出行。 

                </div>
                
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
        <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/footer'
import { fetch } from '../api/https';
export default {
  data() {
    return {
      banner:''
    };
  },
  components:{
      Footer
  },
  created() {},
  mounted() {
    this.axioBanner()
    if (localStorage.getItem("gotraffic")) {
      this.goTrafficRoutes();
      localStorage.removeItem("gotraffic");
    }
    if (localStorage.getItem("gonotice")) {
      this.goNotice();
      localStorage.removeItem("gonotice");
    }
  },
  methods: {
    goTrafficRoutes() {
      let el = document.querySelector("#frame_content_view");
      if (el) {
        el.scrollTop = this.$refs.trafficRoutes.getBoundingClientRect().top - 97;
      }
    },
    goNotice() {
      let el = document.querySelector("#frame_content_view");
      if (el) {
        el.scrollTop = this.$refs.notice.getBoundingClientRect().top - 167;
      }
    },
    axioBanner(){
      fetch('/getMenu').then((res) => {
          this.banner = res.data[7].child[2].menu_img

          document.title = res.data[7].child[2].menu_seo_title

      }).catch((err) => {
        
      });

          var map = new BMapGL.Map("allmap");
    var point = new BMapGL.Point(116.677106,40.116104);
    map.centerAndZoom(point, 17);
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    var marker = new BMapGL.Marker(point); // 创建标注
    map.addOverlay(marker); // 将标注添加到地图中
    var opts = {
      width: 150, // 信息窗口宽度
      height: 80, // 信息窗口高度
      title: "顺义博物馆", // 信息窗口标题
      message: "这里是顺义博物馆",
    };
    var infoWindow = new BMapGL.InfoWindow(
      "地址：北京市顺义区石园街道石园南大街顺义文化中心内</br>电话：010-81475286转1",
      opts
    ); // 创建信息窗口对象
    // marker.addEventListener("click", function () {
      map.openInfoWindow(infoWindow, point); //开启信息窗口
    // });
        marker.addEventListener("click", function () {
      map.openInfoWindow(infoWindow, point); //开启信息窗口
    });
      // var infoWindow = new BMap.InfoWindow(content, infoOptions);  // 创建信息窗口对象
      // map.openInfoWindow(infoWindow, point);
    },

  },
};
</script>

<style scoped>
#allmap{
width: 504px;
height: 453px;
float: left;
}
.luxianCont{
 width: 696px;
 overflow: hidden;
}
.banner {
  height: 600px;
  width: 100%;
  position: relative;
}
.banner img {
  height: 100%;
  width: 100%;
}
.back{
  width: 100%;
    background: url("../assets/tourService/back.png") no-repeat center;
  background-size: 1980px 100%;
  z-index: 99999;
    position: relative;

  margin-top: -152px;
  padding-bottom: 60px;
}
.banner_kaifang {
  max-width: 1400px;
  margin: 0 auto;
  /* position: relative;

  margin-top: -105px;
  padding-bottom: 60px; */
}
.banner_kaifang img {
  /* height: 120px; */
  /* margin: 0 auto; */
  /* z-index: 9999; */
  /* margin-top: -108px; */
}
#detail {
  color: #999999;
  width: 80%;
  margin: 0 auto;
  text-align: left;
  text-indent: 2em;
  display: block;
}
.banLeft{
    width: 34%;
    height: 74px;
    font-size: 22px;
}
.banLeft img{
  margin-top: -5px;
}
.banRight{
    width: 66%;
    height: 74px;
    line-height: 32px;
}
.p{
  font-size: 16px;
  line-height: 38px;
}
.titles {
  clear: both;
  color: #e2be67;
  font-size: 28px;
  text-align: center;
  z-index: 10000;
  margin: 0 auto;
font-weight: 400;
max-width: 1400px;
font-size: 28px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #E2BE67;
line-height: 43px;
padding-top: 87px;
padding-bottom: 38px;
}
.titles img {
  width: 57px;
  height: 26px;
  margin:0 39px;
}
.doalan_top {
  width: 1366px;
  height: 400px;
  margin: 0 auto;
  padding: 0 157px;
  box-sizing: border-box;
  background: url('../assets/tourService/bgFigure.png');
  position: relative;
}
.doalan_top .doalan_lei {
  /* background-color: yellow; */
  height: 20px;
  width: 100%;
  /* padding-left: 108px; */
  /* padding-right: 108px; */
  box-sizing: border-box;
}
.doalan_kai {
  font-size: 33px;
  margin-right: 10px;
  float: left;
  /* color: #e2be67; */
  /* height: 50px;
  line-height: 50px; */
  font-size: 44px;
font-family: Source Han Sans CN;
font-weight: 500;
color: #E0BA61;
}
.doalan_kaies {
  float: right;
  line-height: 50px;
  font-size: 44px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #E0BA61;
}
.doalan_kais { 
  font-size: 30px;
  margin-right: 10px;
  margin-left: 10px;
  color: #e2be67;
  box-sizing: border-box;
  display: inline-block;
  float: left;
}

.doalan_kais img{
  width:107px;
  height:63px;
  float: left;
}
.left {
  float: left;
}
.lefts {
  float: left;
  margin-top: -4px;
  font-size: 16px;
  z-index: 1;
  position: relative;
}
.leftes {
  float: left;
  margin-top: 20px;
  font-size: 17px;
}
.doalan_right {
  float: right;
  width: 395px;
}
.doalan_righttwo {
  float: right;
  width: 385px;
  position:absolute;
  right: 70px;
  top: 0;
}
/* .rights {
  float: right;
} */
.colorEs {
  /* clear:none; */
  display: block;
  /* line-height: 60px; */
  color: #e2be67;
   padding-top:48px;
  font-size: 22px;
font-family: PingFang SC;
font-weight: 400;
color: #E2BE67;
}
.doalan_Es {
  clear: both;
    white-space: nowrap;
   padding-top:15px;
   padding-bottom: 99px;
   float: right;
   margin-right: -8px;
   font-size: 22px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #E2BE67;
}
.timeList {
  width: 314px;
  text-align: center;
  
}
.timeList div {
  font-size: 30px;margin-top: 57px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;
}
.timeList span {
  font-size: 22px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #999999;
}
.doalan_lie {
  display: block;
  width: 364px;
  float: right;
  font-size: 22px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #666666;
line-height: 36px;
}
#wui {
  width: 280px;
  height: 451px;
  margin-left: -8px;
}
.borderImg {
  width: 1200px;
   margin:30px auto; 
  height: 51px;
  box-sizing: border-box;
  margin-left: 100px;
  margin-bottom: 0;
}
.jianjie {
  width: 1200px;
  margin: auto;
  text-align: center;
  color: #94744b;
}
.white{
  height: 41px;
  width: 100%;
}
.titl {
  clear: both;
  color: #e2be67;
  font-size: 28px;
  text-align: center;
  z-index: 10000;
  margin: 0 auto;
  width: 300px;
  padding-top: 71px;
}
.titl img {
  width: 57px;
  height: 26px;
}
#titl {
  /* clear: both; */
  color: #e2be67;
  /* font-size: 28px; */
  line-height: 40px;
  text-align: center;
  /* z-index: 10000;
  margin: 0 auto; */
  /* width: 300px; */
  /* padding-bottom: 30px; */
  /* box-sizing: border-box; */
  margin-right: 30px;
  position: relative;
}
#detail {
  /* font-size: 24px;
    line-height: 30px; */
}
#titl img {
  width: 26px;
  height: 26px;
}
.zhanListed {
  max-width: 1400px;
  margin: 0 auto;
  /* background-color: aqua; */
  height: 350px;
  margin-top: 30px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
}
.zhanListed img {
  height: 350px;
  width: 650px;
}
.zhanListed div {
  /* height: 350px; */
  width: 700px;
  margin-right: 20px;
}
.xuzhi {
  width: 1200px;
  height: 440px;
  background: url("../assets/tourService/noticeBg.png");
  background-size: cover;
  margin: o auto;
  box-sizing: border-box;  
  margin-left: 100px;
  margin-top: 77px;
}
#su {
  height: 456px;
  width: 205px;
  margin-left: 30px;
  margin-top: -70px;
  margin-right: 20px;
  float: left;
  box-sizing: border-box;
}
#suright {
  height: 149px;
  width: 48px;
  margin-top: 50px;
  background: url("../assets/tourService/visitInfo.png");
  background-size: contain;
  float: left;
  margin-right: 25px;
font-size: 20px;
color: #FFFFFF;
  box-sizing: border-box;
}
#suDetails {
  height: 354px;
  width: 824px;
  margin-top: 48px;
  font-size: 18px;
line-height: 26px;
  float: left;
  color: white;
}
#suDetails .cp{
  font-size: 17px;
  font-family: PingFangSC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.9;
  text-indent: 2em;
}
.yuyue {
  position: relative;
  width: 1200px;
  height: 390px;
  text-align: center;
  padding-left: 100px;
}
.yuyue img {
  position: absolute;
  height: 236px;
  width: 236px;
  top: 50%;
  margin-top: -118px;
  left: 582px;
  z-index: 10000;
}
.yuyueleft {
  width: 600px;
  height: 390px;
  text-align: center;
  /* margin-top: 37px; */
  background: url("../assets/tourService/serviceBg.png");
  background-size: cover;
  /* float: left; */
  line-height: 25px;
  color: white;
}
.yuyueright {
  width: 600px;
  height: 390px;
  text-align: center;
  background: url("../assets/tourService/biankuang.png") no-repeat;
  background-size: 100% 100%;
  padding-left: 25px;
  box-sizing: border-box;
  line-height: 25px;
  color: white;
}
.yuyueright .yue {
  /* 需要加背景 */
  /* background: turquoise; */
  height: 286px;
  width: 600px;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 18px;
  color: #9b7d57;
}
.pinTitle {
  padding-top: 81px;
  padding-bottom:30px;  
  font-size: 24px;
font-family: PingFangSC;
font-weight: 400;
color: #FFFFFF;
  /*  */
}
.zongColor {
  color: #9b7d57;
}
.timesList span{
  line-height: 40px;
}
.bianmin{
    max-width: 1400px;
}
.bianChilren{
    margin-left: 90px;
    float: left;
    width: 580px;
    
}
.bianChilren img{
    height: 74px;
    width: 102px;
    float: left;
    margin-right: 26px;
}
.bianChilren .header2{
    font-weight: 900;
    font-size: 22px;
    margin-bottom:20px;
    padding: 20px;
    font-weight: bold;
    padding-left: 0px;
    height:200px;
}
.bianChilren .spans{
    color: #666666;
    font-weight: bold;
    font-weight: 900;
    font-size: 18px;
    margin-bottom:20px;
    margin-top: 20px;
    display: block;
    width:400px;
    float: left;
}
.mbd{
    margin-bottom: 20px;
    
}
.luxian{
    width: 1200px;
    height: 480px;
    margin-top: 40px;
    padding-left: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.maps{
    background: url('../assets/tourService/tuceng15.png');
    
    background-size: cover;
}
.mapsRight{
    float: right;
    width: 696px;
}
.mapsRights{
    float: right;
    height: 200px;
    width: 696px;
}
.headthree{
    height: 101px;
    width: 696px;
    background: #ddc090;
    padding-left: 40px;
    /* padding-right: 10%; */
    box-sizing: border-box;
    font-size: 19px;
    /* line-height: 40px; */
    color: white;
    border-bottom: 1px solid #cccccc;
}
.heads{
    height: 72px;
    width: 696px;
    background:rgba(255, 217, 141);
    padding-left: 40px;
    box-sizing: border-box;
    font-size: 22px;
    padding-top: 25px;
    color: white;
}
.head{
    height: 285px;
    width: 696px;
    margin-top: -27px;
    background:rgba(255, 217, 141);
    padding-left: 40px;
    box-sizing: border-box;
    font-size: 22px;
    line-height: 32px;
    color: white;
    /* border-bottom: 1px solid #cccccc; */
}
.biankuang{
  width: 617px;
    border-top: 1px solid rgba(255,228,174);

}
.bor div{
  height: 1px;
  width: 616px;
  margin: 0 auto;
  background: #FFFFFF;
  opacity: 0.3;
}
>>>.BMap_bubble_pop{
      padding: 0px 15px !important;
}
.head img{
    width: 216px;
    height: 120px;
    float: right;
    margin-right: 39px;
    margin-top: -136px;
    z-index: 0;
}
.heads img{
    float: left;
    margin-right: 20px;
    width: 30px;
    height: 30px;
}
.up{
    margin-left: 80px;
    position: absolute;
    left: 43%;
    top: 5%;
     margin-top: 80px; 
}
.down{
    margin-left: 80px;
    position: absolute;
    left: 30%;
    top: -2%;
    /* margin-top: 80px; */
}
.upborder{
  width: 355px;
  display: flex;
  justify-content: space-between;
  height: 68px;

}
.upborder img{
  height: 70px;
  width: 70px;
}
pre{
  font-family: PingFangSC-Regular;
  padding-left: 26px;
}
.danlan_bianmin{
  width: 1300px;
  padding: 0 98px;
  box-sizing: border-box;
  height: 400px;
  padding-bottom: 65px;
  margin: 0 auto;
  margin-top: 62px;
  /* display: flex; */
  /* flex-direction:column; */
  /* align-items: center;  */
   /* justify-content: space-around; */
   /* flex-wrap: wrap; */
}
.daolan_out{
  width: 445px;
  height: auto;
  float: left;
  margin: 0 44px;;
}
.daolan_marBottomOne{
  margin-bottom: 63px;
}
.daolan_marBottomTwo{
  margin-bottom: 66px;
}
.daolan_titleImg{
  width: 102px;
  /* height: 74px; */
  float: left;
}
.daolan_title{
  margin-bottom: 27px;
  display: block;
}
.daolan_titleImg img{
  width: 102px;
  height: 74px;
  margin-right: 26px;
}
.daolan_details{
  font-size: 22px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;

}
.daolan_text{
  width: 316px;
  height: auto;
  float: right;
  font-size: 26px;
  font-family: PingFangSC;
  font-weight: 540;
  color: #333333;
  
}

</style>
<style>
.shanz {
  width: 62px;
  height: 37px;
  float: left;
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 5px;
}

@media screen  and (max-width: 1440px) {
    .back {
    background: url(/img/back.613ec19e.png) no-repeat center;
    background-size: 1740px 100% !important;
    z-index: 99999;
    position: relative;
    margin-top: -152px;
    padding-bottom: 60px;
  }
  .doalan_righttwo{
    right: 105px;
  }
}
@media screen and (max-width: 1119px) {
  .doalan_righttwo{
    position: absolute;
    right: 70px;
    top: 0;
  }
}

</style>